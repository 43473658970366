import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import tw, { styled } from "twin.macro"
import { Hero } from "../components/hero"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import Img from "gatsby-image"
import { ForSaleCard } from "../components/ForSaleCard"
import { AttachmentsBlock } from "../components/AttachmentsBlock"

export const query = graphql`
  query ForSaleList($skip: Int, $limit: Int) {
    allSanityForSale(
      skip: $skip
      limit: $limit
      sort: { fields: _updatedAt, order: DESC }
    ) {
      totalCount
      nodes {
        order
        _id
        _createdAt(formatString: "dddd MMMM Do, YYYY hh:mma")
        _updatedAt(formatString: "dddd MMMM Do, YYYY hh:mma")
        weight
        type
        location
        notes
        buildYear
        status
        number
        saleStatus
        image {
          asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 400, cropFocus: CENTER) {
                  src
                }
                fullsize: fluid(maxWidth: 1000) {
                  src
                }
              }
            }
          }
        }
      }
    }

    sanityPage(slug: { current: { eq: "for-sale" } }) {
      content {
        ... on SanityForSaleBlock {
          _key
          _type
          countPerPage
          warningBlockCopy
          warningBlockTitle
        }
        ... on SanityAttachmentsBlock {
          _key
          _type
          attachments {
            asset {
              url
              originalFilename
              mimeType
            }
          }
        }
      }
      hero {
        _key
        headline
        tagline
        background {
          asset {
            url
            localFile {
              childImageSharp {
                fluid(cropFocus: CENTER, grayscale: true, maxWidth: 2000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

let Button = tw.div`
text-white bg-primary inline-block py-2 px-4 cursor-pointer self-start mr-4
`

let Container = tw.section`
    container py-4 -mt-16 relative
`

let Icon = styled(FontAwesomeIcon)`
  ${tw`mx-2 `}
`

let ForSale = props => {
  const { currentPage, numForSalePages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numForSalePages
  const prevPage =
    currentPage - 1 === 1
      ? "/for-sale"
      : `/for-sale/${(currentPage - 1).toString()}`
  const nextPage = `/for-sale/${(currentPage + 1).toString()}`
  return (
    <Layout>
      <SEO
        title={"For Sale"}
        image={props.data.sanityPage.hero.background.asset.url}
      />
      <Hero key={"hero"} {...props.data.sanityPage?.hero} salesPage={true} />
      <Container>
        {props.data.sanityPage.content.map(block => {
          if (block._type === "attachmentsBlock") {
            return (
              <div tw={" pt-4 pb-12"}>
                <h2 tw={"font-sans font-bold text-2xl"}>Documents</h2>
                {block.attachments.map(({ asset: a }) => (
                  <AttachmentsBlock {...a} />
                ))}
              </div>
            )
          }
          if (block._type === "forSaleBlock")
            return (
              <div
                tw={"bg-blue-700 py-10 px-6 lg:py-6 lg:px-4"}
                key={block._key}
              >
                <h2
                  tw={
                    "font-sans mb-4 font-bold tracking-wide text-2xl text-blue-100"
                  }
                >
                  {block.warningBlockTitle}
                </h2>
                <p tw={"font-sans text-lg text-blue-200"}>
                  {block.warningBlockCopy}
                </p>
                <p tw={"font-sans text-lg text-blue-100 mt-4"}>
                  Sale is by tender/best offer. Please submit offers by emailing
                  sales@RMSlocotec.com
                </p>
              </div>
            )
        })}
        <div tw={"lg:grid-cols-3 gap-3 grid items-stretch mb-12"}>
          {props?.data?.allSanityForSale.nodes.map(fleet => (
            <ForSaleCard {...fleet} key={fleet._id} />
          ))}
        </div>
        <div>
          {!isFirst && (
            <Button>
              <Link to={prevPage} rel="prev">
                <Icon icon={faArrowLeft} />
                Previous Page
              </Link>
            </Button>
          )}
          {!isLast && (
            <Button>
              <Link to={nextPage} rel="next">
                Next Page
                <Icon icon={faArrowRight} />
              </Link>
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default ForSale
